import { Group, Radio } from '@mantine/core';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { radioValue } from '../../utils/fieldValidators';

const requiredRule = {
  validate: (value?: boolean) => {
    if (value === true || value === false) {
      return undefined;
    }

    return 'Required';
  },
};

const RadioYesNo = ({
  control,
  name,
  label,
  yesLabel = 'Yes',
  noLabel = 'No',
  isDisabled = false,
  defaultValue = false,
  required = false,
}: {
  control: Control<any>;
  name: string;
  label?: string;
  yesLabel?: string;
  noLabel?: string;
  isDisabled?: boolean;
  defaultValue?: boolean;
  required?: boolean;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={required ? requiredRule : undefined}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <Radio.Group
          {...field}
          onChange={(value) => field.onChange(value === 'true')}
          value={radioValue(field.value)}
          label={label}
          error={fieldState.error?.message}
          required={required}
        >
          <Group>
            <Radio
              disabled={isDisabled}
              key="yes"
              value="true"
              data-testid={`${name} ${yesLabel}`}
              label={yesLabel}
            />
            <Radio
              disabled={isDisabled}
              key="no"
              value="false"
              data-testid={`${name} ${noLabel}`}
              label={noLabel}
            />
          </Group>
        </Radio.Group>
      )}
    />
  );
};

export default RadioYesNo;
