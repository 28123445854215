import { Group, Avatar } from '@mantine/core';
import React from 'react';

const Assignee = ({
  imageUrl,
  initials,
  name,
}: {
  imageUrl?: string | null;
  initials?: string | null;
  name?: string | null;
}) =>
  name ? (
    <Group gap="xs" style={{ flexWrap: 'nowrap' }}>
      <Avatar src={imageUrl} radius="xl" size="sm">
        {initials}
      </Avatar>
      <span>{name}</span>
    </Group>
  ) : null;

export default Assignee;
