import { TextInput } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { UseFormSetFocus, UseFormSetValue } from 'react-hook-form';
import { parseAddress, parseAddressState } from '../../utils/utils';

const GooglePlacesAutocompleteField = ({
  setValue,
  setFocus,
}: {
  setValue: UseFormSetValue<any>;
  setFocus: UseFormSetFocus<any>;
}) => {
  const [address, setAddress] = useState('');
  const autoCompleteInputRef = useRef<HTMLInputElement>(null);
  const autoCompleteRef = useRef<any>(undefined);

  const handlePlaceSelect = async () => {
    const autoComplete = autoCompleteRef.current;
    if (!autoComplete) {
      return;
    }

    const place = autoComplete.getPlace();

    if (!place.address_components || !place.formatted_address) {
      return;
    }

    setAddress(place.formatted_address);

    const addressLine1 = place.name;
    const city = parseAddress(place.address_components, 'locality');
    const state = parseAddressState(place.address_components, 'administrative_area_level_1');
    const zipcode = parseAddress(place.address_components, 'postal_code');

    setValue('address1', addressLine1);
    setValue('city', city);
    setValue('state', state);
    setValue('zipcode', zipcode);

    setFocus('address2');
  };

  useEffect(() => {
    if (!autoCompleteInputRef.current) {
      return;
    }

    // @ts-expect-error
    const autoComplete = new google.maps.places.Autocomplete(autoCompleteInputRef.current, {});
    autoComplete.addListener('place_changed', handlePlaceSelect);

    autoCompleteRef.current = autoComplete;
  }, []);

  return (
    <TextInput
      id="autocomplete"
      defaultValue={address}
      label="Search for a Address"
      type="text"
      ref={autoCompleteInputRef}
      placeholder=""
    />
  );
};

export default GooglePlacesAutocompleteField;
