import { Badge } from '@mantine/core';
import { isNil } from 'lodash';
import React from 'react';

export const QuoteOpen = ({ isOpen }: { isOpen: boolean | string | undefined }) => {
  if (isNil(isOpen)) {
    return null;
  }
  if (isOpen === true || isOpen === 'true') {
    return (
      <Badge size="sm" color="green">
        OPEN
      </Badge>
    );
  }
  return (
    <Badge size="sm" color="blue">
      CLOSED
    </Badge>
  );
};
