import { PolicyProduct } from '../graphql/types.generated';

export const BrokeragesStep = {
  BROKERAGES: 'brokerages',
  BROKER_OFFICES: 'Broker Offices',
  BROKERS: 'Brokers',
} as const;
type BrokeragesStepType = typeof BrokeragesStep[keyof typeof BrokeragesStep];

export const PoliciesStep = {
  MINE: 'myPolicies',
  ALL: 'policyGrid',
} as const;
type PoliciesStepType = typeof PoliciesStep[keyof typeof PoliciesStep];

export const AuditsStep = {
  FILE_PREPS: 'filePreps',
  AUDITS: 'audits',
} as const;
type AuditsStepType = typeof AuditsStep[keyof typeof AuditsStep];

export const PolicyStep = {
  LICENSES: 'Licenses',
  INVOICES: 'Invoices',
  QUOTES: 'Quotes',
  UNDERWRITING_FILE: 'Files',
} as const;
export type PolicyStepType = typeof PolicyStep[keyof typeof PolicyStep];

export const QuoteStep = {
  PRICING: 'Pricing',
  FORMS: 'Forms',
  SUBJECTIVITIES: 'Subjectivities',
  UNDERLYING_POLICIES: 'Underlying Policies',
  POLICY_DOCS: 'Policy Docs',
  POLICY_CHANGE_ENDORSEMENTS: 'Policy Change Endorsements',
} as const;
type QuoteStepType = typeof QuoteStep[keyof typeof QuoteStep];

export const SelfAuditStep = {
  MY_SELF_AUDITS: 'My Self Audits',
  SELF_AUDITS: 'Self Audits',
} as const;
type SelfAuditStepType = typeof SelfAuditStep[keyof typeof SelfAuditStep];

export const ProductStep = {
  ...PolicyProduct,
  ERROR: 'error',
} as const;
type ProductStepType = typeof ProductStep[keyof typeof ProductStep];

export const Step = {
  ERROR: 'error',
  RETURN_TO: 'returnTo',
  PRODUCT: 'product',
  ISSUED_POLICY: 'issuedPolicy',
  TAB: 'tab',
} as const;
type StepType = typeof Step[keyof typeof Step];

type AllowedStepTypes =
  | StepType
  | BrokeragesStepType
  | PoliciesStepType
  | PolicyStepType
  | BrokeragesStepType
  | QuoteStepType
  | SelfAuditStepType
  | ProductStepType
  | AuditsStepType;

export const parseStep = <T extends AllowedStepTypes>(
  step: string | null | undefined,
  defaultStep: T,
  stepSet: { [key: string]: T }
): T => {
  if (!step || !Object.values(stepSet).includes(step as any)) {
    return defaultStep;
  }

  return step as T;
};
