import { IndicatedLostReason, QuotedLostReason, QuoteStatus } from '../../graphql/types.generated';
import React from 'react';
import { $TsFixMe } from '../../../module';
import { Badge, MantineSize, Tooltip } from '@mantine/core';

export const displayQuotedLostReason = (
  quotedLostReason: QuotedLostReason,
  quotedLostNotes: QuotedLostReason
) => {
  switch (quotedLostReason) {
    case QuotedLostReason.Price:
      return 'Price (non-competitive)';
    case QuotedLostReason.Terms:
      return 'Terms and Conditions';
    case QuotedLostReason.Renewed:
      return 'Renewed with Incumbent';
    case QuotedLostReason.Broker:
      return 'Broker Lost';
    case QuotedLostReason.Capacity:
      return 'Requested capacity out of appetite';
    case QuotedLostReason.Attachment:
      return 'Requested attachment out of appetite';
    case QuotedLostReason.Nonadmitted:
      return 'Non-admitted paper';
    case QuotedLostReason.AdditionalLimits:
      return 'Additional limits not purchased';
    case QuotedLostReason.Other:
      return `Other: ${quotedLostNotes}`;
    default:
      return quotedLostReason;
  }
};

export const displayIndicatedLostReason = (
  indicatedLostReason: IndicatedLostReason,
  indicatedLostNotes: IndicatedLostReason
) => {
  switch (indicatedLostReason) {
    case IndicatedLostReason.Capacity:
      return 'Requested capacity out of appetite';
    case IndicatedLostReason.Attachment:
      return 'Requested attachment out of appetite';
    case IndicatedLostReason.Price:
      return 'Price (non-competitive)';
    case IndicatedLostReason.Terms:
      return 'Terms and Conditions';
    case IndicatedLostReason.Renewed:
      return 'Renewed with incumbent';
    case IndicatedLostReason.Broker:
      return 'Broker lost';
    case IndicatedLostReason.Nonadmitted:
      return 'Non-admitted paper';
    case IndicatedLostReason.AdditionalLimits:
      return 'Additional limits not purchased';
    case IndicatedLostReason.Other:
      return indicatedLostReason;
    default:
      return indicatedLostReason;
  }
};

const QuoteBadgeStatus = ({
  status,
  quote,
  shouldDisplayIndicatedLostReason = true,
  shouldDisplayQuotedLostReason = true,
  size = 'md',
}: {
  status: QuoteStatus;
  quote?: $TsFixMe;
  shouldDisplayIndicatedLostReason?: boolean;
  shouldDisplayQuotedLostReason?: boolean;
  size?: MantineSize;
}) => {
  switch (status) {
    case QuoteStatus.PendingClearance:
      return (
        <Badge size={size} color="yellow">
          PENDING CLEARANCE
        </Badge>
      );
    case QuoteStatus.Issued:
      return (
        <Badge size={size} color="violet">
          ISSUED
        </Badge>
      );
    case QuoteStatus.Accepted:
      return (
        <Badge size={size} color="green">
          ACCEPTED
        </Badge>
      );
    case QuoteStatus.Quoted:
      return (
        <Badge size={size} color="blue">
          QUOTED
        </Badge>
      );
    case QuoteStatus.Declined:
      return (
        <Badge size={size} color="red">
          DECLINED
        </Badge>
      );
    case QuoteStatus.Draft:
      return (
        <Badge size={size} color="orange">
          DRAFT
        </Badge>
      );
    case QuoteStatus.Discarded:
      return (
        <Badge size={size} color="pink">
          DISCARDED
        </Badge>
      );
    case QuoteStatus.Blocked:
      return (
        <Badge size={size} color="red">
          BLOCKED
        </Badge>
      );
    case QuoteStatus.Indicated:
      return (
        <Badge size={size} color="yellow">
          INDICATED
        </Badge>
      );
    case QuoteStatus.IndicatedLost: {
      const ilBadge = (
        <Badge size={size} color="red">
          INDICATED LOST
        </Badge>
      );
      if (shouldDisplayIndicatedLostReason) {
        const reason = displayIndicatedLostReason(
          quote?.indicatedLostReason,
          quote?.indicatedLostNotes
        );
        return (
          <Tooltip maw="15rem" multiline label={reason}>
            {ilBadge}
          </Tooltip>
        );
      }
      return ilBadge;
    }
    case QuoteStatus.QuotedLost: {
      const qlBadge = (
        <Badge size={size} color="red">
          QUOTED LOST
        </Badge>
      );

      if (shouldDisplayQuotedLostReason) {
        const reason = displayQuotedLostReason(quote?.quotedLostReason, quote?.quotedLostNotes);
        return (
          <Tooltip maw="15rem" multiline label={reason}>
            {qlBadge}
          </Tooltip>
        );
      }
      return qlBadge;
    }
    default:
      return (
        <Badge size={size} color="yellow">
          N/A
        </Badge>
      );
  }
};
export default QuoteBadgeStatus;
