import { Tooltip } from '@mantine/core';
import React, { ReactNode } from 'react';

const ShortcutTooltip = ({
  children,
  actionName,
  shortcut,
}: {
  children: ReactNode;
  actionName: string;
  shortcut: string;
}) => {
  return (
    <Tooltip openDelay={700} position="bottom" label={`${actionName} · ${shortcut}`}>
      {children}
    </Tooltip>
  );
};

export default ShortcutTooltip;
